import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { API_BASE_URL } from '../config/api';

const Material = () => {
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [purpose, setPurpose] = useState('');
    const [currentIndex, setCurrentIndex] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedSpecification, setSelectedSpecification] = useState(null);
    const [imageCarousel, setImageCarousel] = useState([]);
    const [materialMains, setMaterialMains] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchMaterialMains();
    }, []);

    const fetchMaterialMains = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/materialMains/allMaterialMains`);
    
            // Parse imageCarousel if it's a JSON string
            const materialMains = response.data.map(materialMain => {
                return {
                    ...materialMain,
                    imageCarousel: Array.isArray(materialMain.imageCarousel)
                        ? materialMain.imageCarousel
                        : JSON.parse(materialMain.imageCarousel || '[]')
                };
            });
    
            setMaterialMains(materialMains);
        } catch (error) {
            console.error('Error fetching materialMains:', error);
        }
    };
    

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file || null);
    };

    const handleSpecificationChange = (e) => {
        const file = e.target.files[0];
        setSelectedSpecification(file || null);
    };

    const handleImageCarouselChange = (e) => {
        const files = Array.from(e.target.files);
        setImageCarousel(files);
    };

    const handleDeleteMaterialMain = async (index) => {
        const materialMain = materialMains[index];
        try {
            await axios.delete(`${API_BASE_URL}/api/materialMains/${materialMain.id}`);
            setMaterialMains(materialMains.filter((_, i) => i !== index));
        } catch (error) {
            console.error('Error deleting materialMain:', error);
        }
    };

    const handleEditMaterialMain = (index) => {
        const materialMain = materialMains[index];
        setTitle(materialMain.title);
        setDesc(materialMain.desc);
        setPurpose(materialMain.purpose || '');
        setCurrentIndex(index);
        setSelectedImage(null);
        setSelectedSpecification(null);
        setImageCarousel([]); // Reset image carousel on edit
    };

    const handleUpdateMaterialMain = async () => {
        if (currentIndex !== null) {
            const materialMainToUpdate = materialMains[currentIndex];

            const formData = new FormData();
            formData.append('title', title);
            formData.append('desc', desc);
            if (purpose) {
                formData.append('purpose', purpose);
            }
            if (selectedImage) {
                formData.append('image', selectedImage);
            }
            if (selectedSpecification) {
                formData.append('specification', selectedSpecification);
            }
            imageCarousel.forEach((file, index) => {
                formData.append(`imageCarousel`, file);
            });

            try {
                const response = await axios.put(
                    `${API_BASE_URL}/api/materialMains/${materialMainToUpdate.id}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                const parsedMaterialMain = {
                    ...response,
                    imageCarousel: response.imageCarousel
                        ? JSON.parse(response.imageCarousel)
                        : [], // Default to empty array if imageCarousel is not present
                };
                fetchMaterialMains();
                resetForm();
            } catch (error) {
                console.error('Error updating materialMain:', error);
            }
        }
    };

    const handleCreateMaterialMain = async () => {
        alert("create");
        if (title && desc) {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('desc', desc);
            if (purpose) {
                formData.append('purpose', purpose);
            }
            if (selectedImage) {
                formData.append('image', selectedImage);
            }
            if (selectedSpecification) {
                formData.append('specification', selectedSpecification);
            }
            imageCarousel.forEach((file) => {
                formData.append('imageCarousel', file);
            });
    
            try {
                const response = await axios.post(
                    `${API_BASE_URL}/api/materialMains/addMaterialMain`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                setMaterialMains([...materialMains, response.data]);
                resetForm();
            } catch (error) {
                console.error('Error creating materialMain:', error);
                setError('Failed to create material main');
            }
        } else {
            setError('Title and Description are required');
        }
    };
    

    const resetForm = () => {
        setTitle('');
        setDesc('');
        setPurpose('');
        setSelectedImage(null);
        setSelectedSpecification(null);
        setImageCarousel([]);
        setCurrentIndex(null);
        setError('');
    };


    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Material Management</h1>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <input
                    type="text"
                    placeholder="Description"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <input
                    type="text"
                    placeholder="Purpose (Optional)"
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <input
                    type="file"
                    onChange={handleImageChange}
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <label htmlFor="">Specification Image</label>
                <input
                    type="file"
                    onChange={handleSpecificationChange}
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <label htmlFor="">Image Carousel</label>
                <input
                    type="file"
                    multiple
                    onChange={handleImageCarouselChange}
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                {error && <p className="text-red-500">{error}</p>}
                {currentIndex === null ? (
                    <button
                        onClick={handleCreateMaterialMain}
                        className="bg-blue-500 text-white p-2 rounded"
                    >
                        Create
                    </button>
                ) : (
                    <button
                        onClick={handleUpdateMaterialMain}
                        className="bg-green-500 text-white p-2 rounded"
                    >
                        Update
                    </button>
                )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {materialMains.map((materialMain, index) => (
                    <div key={index} className="p-4 border border-gray-300 rounded">
                        {materialMain.image && (
                            <img
                                src={`${API_BASE_URL}/${materialMain.image}`}
                                alt="Material"
                                className="w-full h-48 object-cover mb-4"
                            />
                        )}
                        <h2 className="text-xl font-bold mb-2">{materialMain.title}</h2>
                        <p className="mb-2">{materialMain.desc}</p>
                        <p className="mb-2">{materialMain.purpose}</p>
                        {materialMain.specification && (
                            <img
                                src={`${API_BASE_URL}/${materialMain.specification}`}
                                alt="Specification"
                                className="w-full h-48 object-cover mb-4"
                            />
                        )}
                        {materialMain.imageCarousel && materialMain.imageCarousel.map((url, idx) => (
                            <img
                                key={idx}
                                src={`${API_BASE_URL}/${url}`}
                                alt={`Carousel ${idx}`}
                                className="w-full h-48 object-cover mb-4"
                            />
                        ))}
                        <button
                            onClick={() => handleEditMaterialMain(index)}
                            className="text-blue-500 mr-2"
                        >
                            <AiOutlineEdit />
                        </button>
                        <button
                            onClick={() => handleDeleteMaterialMain(index)}
                            className="text-red-500"
                        >
                            <MdOutlineDeleteForever />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Material;
