/* eslint-disable react/prop-types */
import {
  RiBold,
  RiH1,
  RiH2,
  RiItalic,
  RiListRadio,
  RiStrikethrough,
  RiUnderline,
} from "react-icons/ri";
import { useCallback } from "react";

const Toolbar = ({ editor }) => {

  const addImage = useCallback(() => {
    const url = window.prompt('URL')

    if (url) {
      editor.chain().focus().setImage({ src: url }).run()
    }
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <div className="relative flex flex-row gap-4 items-center justify-between">
      <div className="flex flex-row gap-1">
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={`${
            editor.isActive("heading", { level: 1 })
              ? "bg-blue-500 text-white"
              : ""
          } size-[30px] grid place-content-center bg-Background2 border border-border/5 rounded text-MainBlack/80`}
        >
          <RiH1 />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={`${
            editor.isActive("heading", { level: 2 })
              ? "bg-blue-500 text-white"
              : ""
          } size-[30px] grid place-content-center bg-Background2 border border-border/5 rounded text-MainBlack/80`}
        >
          <RiH2 />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`${
            editor.isActive("bold") ? "bg-blue-500 text-white" : ""
          } size-[30px] grid place-content-center bg-Background2 border border-border/5 rounded text-MainBlack/80`}
        >
          <RiBold />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`${
            editor.isActive("italic") ? "bg-blue-500 text-white" : ""
          } size-[30px] grid place-content-center bg-Background2 border border-border/5 rounded text-MainBlack/80`}
        >
          <RiItalic />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={`${
            editor.isActive("underline") ? "bg-blue-500 text-white" : ""
          } size-[30px] grid place-content-center bg-Background2 border border-border/5 rounded text-MainBlack/80`}
        >
          <RiUnderline />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`${
            editor.isActive("strike") ? "bg-blue-500 text-white" : ""
          } size-[30px] grid place-content-center bg-Background2 border border-border/5 rounded text-MainBlack/80`}
        >
          <RiStrikethrough />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`${
            editor.isActive("bulletList") ? "bg-blue-500 text-white" : ""
          } size-[30px] grid place-content-center bg-Background2 border border-border/5 rounded text-MainBlack/80`}
        >
          <RiListRadio />
        </button>
        {/* <button onClick={addImage}>Set image</button> */}
      </div>
    </div>
  );
};

export default Toolbar;
