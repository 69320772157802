/* eslint-disable react/prop-types */
import StarterKit from "@tiptap/starter-kit";
import { useEditor, EditorContent } from "@tiptap/react";
import Toolbar from "./Toolbar";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Image from '@tiptap/extension-image';
const RichTextEditor = ({ content, onChange }) => {
  const handleChange = (newContent) => {
    onChange(newContent);
  };

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Underline,
        Placeholder.configure({
          placeholder: "Type something...",
        }),
        Paragraph,
        Text,
        Image,
        Heading.configure({
          levels: [1, 2, 3],
        }),
      ],
      editorProps: {
        attributes: {
          class:
            "border border-border rounded-md p-4 focus:outline-none focus:ring-Color3 focus:ring-[1px] text-MainBlack/80 prose ",
        },
      },
      onUpdate: ({ editor }) => {
        handleChange(editor.getHTML());
      },
    },
    []
  );
  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  return (
    <div className="w-full flex flex-col gap-2 ">
      <Toolbar editor={editor} />
      <EditorContent style={{ whiteSpace: "pre-line" }} editor={editor} />
    </div>
  );
};

export default RichTextEditor;
