import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDeleteForever } from "react-icons/md";
import { API_BASE_URL } from "../config/api";

const RawMaterial = () => {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [purpose, setPurpose] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSpecification, setSelectedSpecification] = useState(null);
  const [imageCarousel, setImageCarousel] = useState([]);
  const [rawMains, setRawMains] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchRawMains();
    console.log(rawMains);
  }, []);

  const fetchRawMains = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/rawMains/allRawMains`
      );

      // Parse imageCarousel if it's a JSON string
      const rawMains = response.data.map((rawMain) => {
        return {
          ...rawMain,
          imageCarousel: Array.isArray(rawMain.imageCarousel)
            ? rawMain.imageCarousel
            : JSON.parse(rawMain.imageCarousel || "[]"),
        };
      });

      setRawMains(rawMains);
    } catch (error) {
      console.error("Error fetching rawMains:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file || null);
  };

  const handleImageCarouselChange = (e) => {
    const files = Array.from(e.target.files);
    setImageCarousel(files);
  };

  const handleSpecificationChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedSpecification(file);
    }
  };

  const handleDeleteRawMain = async (index) => {
    const rawMain = rawMains[index];
    try {
      await axios.delete(`${API_BASE_URL}/api/rawMains/${rawMain.id}`);
      setRawMains(rawMains.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting rawMain:", error);
    }
  };

  const handleEditRawMain = (index) => {
    const rawMain = rawMains[index];
    setTitle(rawMain.title);
    setDesc(rawMain.desc);
    setPurpose(rawMain.purpose || "");
    setCurrentIndex(index);
    setSelectedImage(null);
    setSelectedSpecification(null);
    setImageCarousel([]); // Reset image carousel on edit
  };

  const handleUpdateRawMain = async () => {
    if (currentIndex !== null) {
      const rawMainToUpdate = rawMains[currentIndex];

      const formData = new FormData();
      formData.append("title", title);
      formData.append("desc", desc);
      if (purpose) {
        formData.append("purpose", purpose);
      }
      if (selectedImage) {
        formData.append("image", selectedImage);
      }
      if (selectedSpecification) {
        formData.append("specification", selectedSpecification);
      }
      imageCarousel.forEach((file, index) => {
        formData.append("imageCarousel", file);
      });

      try {
        const response = await axios.put(
          `${API_BASE_URL}/api/rawMains/${rawMainToUpdate.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const parsedRawMain = {
          ...response.data,
          imageCarousel: response.data.imageCarousel
            ? JSON.parse(response.data.imageCarousel)
            : [], // Default to empty array if imageCarousel is not present
        };

        setRawMains(
          rawMains.map((rawMain, index) =>
            index === currentIndex ? parsedRawMain : rawMain
          )
        );
        resetForm();
      } catch (error) {
        console.error("Error updating rawMain:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      }
    }
  };

  const handleCreateRawMain = async () => {
    alert("create");
    if (title && desc) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("desc", desc);
      if (purpose) {
        formData.append("purpose", purpose);
      }
      if (selectedImage) {
        formData.append("image", selectedImage);
      }
      if (selectedSpecification) {
        formData.append("specification", selectedSpecification);
      }
      imageCarousel.forEach((file) => {
        formData.append("imageCarousel", file);
      });

      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/rawMains/addRawMain`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //setRawMains([...rawMains, response.data]);
        fetchRawMains();
        resetForm();
        //console.log(imageCarousel);
      } catch (error) {
        console.error("Error creating rawMain:", error);
        setError("Failed to create raw main");
      }
    } else {
      setError("Title and Description are required");
    }
  };

  const resetForm = () => {
    setTitle("");
    setDesc("");
    setPurpose("");
    setSelectedImage(null);
    setSelectedSpecification(null);
    setImageCarousel([]);
    setCurrentIndex(null);
    setError("");
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Raw Material Management</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <input
          type="text"
          placeholder="Description"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <input
          type="text"
          placeholder="Purpose (Optional)"
          value={purpose}
          onChange={(e) => setPurpose(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <input
          type="file"
          onChange={handleImageChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <label htmlFor="">Specification Image</label>
        <input
          type="file"
          onChange={handleSpecificationChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <label htmlFor="">Image Carousel</label>
        <input
          type="file"
          multiple
          onChange={handleImageCarouselChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        {error && <p className="text-red-500">{error}</p>}
        {currentIndex === null ? (
          <button
            onClick={handleCreateRawMain}
            className="bg-blue-500 text-white p-2 rounded"
          >
            Create
          </button>
        ) : (
          <button
            onClick={handleUpdateRawMain}
            className="bg-green-500 text-white p-2 rounded"
          >
            Update
          </button>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rawMains.map((rawMain, index) => (
          <div key={index} className="p-4 border border-gray-300 rounded">
            {rawMain.image && (
              <img
                src={`${API_BASE_URL}/${rawMain.image}`}
                alt="Raw Material"
                className="w-full h-48 object-cover mb-4"
              />
            )}
            <h2 className="text-xl font-bold mb-2">{rawMain.title}</h2>
            <p className="mb-2">{rawMain.desc}</p>
            {rawMain.purpose && <p className="mb-2">{rawMain.purpose}</p>}
            {rawMain.specification && (
              <img
                src={`${API_BASE_URL}/${rawMain.specification}`}
                alt="Specification"
                className="w-full h-48 object-cover mb-4"
              />
            )}
            {rawMain.imageCarousel &&
              rawMain.imageCarousel.map((url, idx) => (
                <img
                  key={idx}
                  src={`${API_BASE_URL}/${url}`}
                  alt={`Carousel ${idx}`}
                  className="w-full h-48 object-cover mb-4"
                />
              ))}
            <button
              onClick={() => handleEditRawMain(index)}
              className="text-blue-500 mr-2"
            >
              <AiOutlineEdit />
            </button>
            <button
              onClick={() => handleDeleteRawMain(index)}
              className="text-red-500"
            >
              <MdOutlineDeleteForever />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RawMaterial;
