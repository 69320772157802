import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDeleteForever } from "react-icons/md";
import { API_BASE_URL } from "../config/api";

const Manufacture = () => {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [purpose, setPurpose] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSpecification, setSelectedSpecification] = useState(null);
  const [imageCarousel, setImageCarousel] = useState([]);
  const [manufactureMains, setManufactureMains] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchManufactureMains();
    console.log(manufactureMains); // Ensure this logs the updated state
  }, []);

  const fetchManufactureMains = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/manufactureMains/allManufactureMains`);

      // Parse imageCarousel if it's a JSON string
      const manufactureMains = response.data.map((manufactureMain) => {
        return {
          ...manufactureMain,
          imageCarousel: Array.isArray(manufactureMain.imageCarousel)
            ? manufactureMain.imageCarousel
            : JSON.parse(manufactureMain.imageCarousel || "[]"),
        };
      });

      setManufactureMains(manufactureMains);
    } catch (error) {
      console.error("Error fetching manufactureMains:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file || null);
  };

  const handleImageCarouselChange = (e) => {
    const files = Array.from(e.target.files);
    setImageCarousel(files);
  };

  const handleSpecificationChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedSpecification(file);
    }
  };

  const handleDeleteManufactureMain = async (index) => {
    const manufactureMain = manufactureMains[index];
    try {
      await axios.delete(`${API_BASE_URL}/api/manufactureMains/${manufactureMain.id}`);
      setManufactureMains(manufactureMains.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting manufactureMain:", error);
    }
  };

  const handleEditManufactureMain = (index) => {
    const manufactureMain = manufactureMains[index];
    setTitle(manufactureMain.title);
    setDesc(manufactureMain.desc);
    setPurpose(manufactureMain.purpose || "");
    setCurrentIndex(index);
    setSelectedImage(null);
    setSelectedSpecification(null);
    setImageCarousel([]); // Reset image carousel on edit
  };

  const handleUpdateManufactureMain = async () => {
    if (currentIndex !== null) {
      const manufactureMainToUpdate = manufactureMains[currentIndex];

      const formData = new FormData();
      formData.append("title", title);
      formData.append("desc", desc);
      if (purpose) {
        formData.append("purpose", purpose);
      }
      if (selectedImage) {
        formData.append("image", selectedImage);
      }
      if (selectedSpecification) {
        formData.append("specification", selectedSpecification);
      }
      imageCarousel.forEach((file) => {
        formData.append("imageCarousel", file);
      });

      try {
        const response = await axios.put(
          `${API_BASE_URL}/api/manufactureMains/${manufactureMainToUpdate.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const parsedManufactureMain = {
          ...response.data,
          imageCarousel: response.data.imageCarousel
            ? JSON.parse(response.data.imageCarousel)
            : [], // Default to empty array if imageCarousel is not present
        };

        setManufactureMains(
          manufactureMains.map((manufactureMain, index) =>
            index === currentIndex ? parsedManufactureMain : manufactureMain
          )
        );
        resetForm();
      } catch (error) {
        console.error("Error updating manufactureMain:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      }
    }
  };

  const handleCreateManufactureMain = async () => {
    if (title && desc) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("desc", desc);
      if (purpose) {
        formData.append("purpose", purpose);
      }
      if (selectedImage) {
        formData.append("image", selectedImage);
      }
      if (selectedSpecification) {
        formData.append("specification", selectedSpecification);
      }
      imageCarousel.forEach((file) => {
        formData.append("imageCarousel", file);
      });

      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/manufactureMains/addManufactureMain`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        fetchManufactureMains();
        resetForm();
      } catch (error) {
        console.error("Error creating manufactureMain:", error);
        setError("Failed to create manufacture main");
      }
    } else {
      setError("Title and Description are required");
    }
  };

  const resetForm = () => {
    setTitle("");
    setDesc("");
    setPurpose("");
    setSelectedImage(null);
    setSelectedSpecification(null);
    setImageCarousel([]);
    setCurrentIndex(null);
    setError("");
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Manufacture Management</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <input
          type="text"
          placeholder="Description"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <input
          type="text"
          placeholder="Purpose (Optional)"
          value={purpose}
          onChange={(e) => setPurpose(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <input
          type="file"
          onChange={handleImageChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <label htmlFor="">Specification Image</label>
        <input
          type="file"
          onChange={handleSpecificationChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <label htmlFor="">Image Carousel</label>
        <input
          type="file"
          multiple
          onChange={handleImageCarouselChange}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        {error && <p className="text-red-500">{error}</p>}
        {currentIndex === null ? (
          <button
            onClick={handleCreateManufactureMain}
            className="bg-blue-500 text-white p-2 rounded"
          >
            Create
          </button>
        ) : (
          <button
            onClick={handleUpdateManufactureMain}
            className="bg-green-500 text-white p-2 rounded"
          >
            Update
          </button>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {manufactureMains.map((manufactureMain, index) => (
          <div key={index} className="p-4 border border-gray-300 rounded">
            {manufactureMain.image && (
              <img
                src={`${API_BASE_URL}/${manufactureMain.image}`}
                alt="Manufacture"
                className="w-full h-48 object-cover mb-4"
              />
            )}
            <h2 className="text-xl font-bold mb-2">{manufactureMain.title}</h2>
            <p className="mb-2">{manufactureMain.desc}</p>
            {manufactureMain.purpose && <p className="mb-2">{manufactureMain.purpose}</p>}
            {manufactureMain.specification && (
              <img
                src={`${API_BASE_URL}/${manufactureMain.specification}`}
                alt="Specification"
                className="w-full h-32 object-cover mb-4"
              />
            )}
            <div className="flex justify-between">
              <button
                onClick={() => handleEditManufactureMain(index)}
                className="text-blue-500 hover:underline"
              >
                <AiOutlineEdit /> Edit
              </button>
              <button
                onClick={() => handleDeleteManufactureMain(index)}
                className="text-red-500 hover:underline"
              >
                <MdOutlineDeleteForever /> Delete
              </button>
            </div>
            <div className="mt-4">
              {manufactureMain.imageCarousel &&
                manufactureMain.imageCarousel.map((image, i) => (
                  <img
                    key={i}
                    src={`${API_BASE_URL}/${image}`}
                    alt={`Carousel ${i}`}
                    className="w-full h-32 object-cover mb-2"
                  />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Manufacture;
